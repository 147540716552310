.certification-form {

  .certification-inputs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }

  .certification-inputs input {
    width: 73.33px;
    height: 70px;
    border-radius: 12px;
    box-shadow: none;
    border: 1px solid #989691;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    text-align: center;
    font-size: 36px;
    color: #009368;
  }

  .certification-inputs input:focus-visible {
    outline-color: #009368;
  }

  .certification-messages .remain-time-message {
    display: flex;
    flex-direction: column;
    color: #84827E;
    font-weight: 500;
  }

  .resend-message {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    color: #504D49;
  }

  .resend-message strong {
    color: #00A872;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }

  .expired-message, .warning-message {
    color: #BB3A3A;
  }
}

@media (max-width: 767.98px) {
  .certification-form {
    .certification-inputs input {
      width: 47.83px;
      height: 70px;
    }
  }
}
