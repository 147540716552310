:root {
  --hej: #00A872;
  --red: #932F2F;
  --gray: #84827E;
  --yellow: #F4950B;
  --brown: #685B52;
  --water-hej: #EEF9F3;
  --water-red: #F2BABA;
  --water-yellow: #FFFBF1;
}

small {
  color: #989691;
}

.hejhome-input {
  border: 1px solid #797672;
  border-radius: 16px;
  padding: 0.5rem 0.75rem !important;
}

.hejhome-input.invalid {
  border: 1px solid #DB6161;
}

.hejhome-input span {
  color: #504D49;
  font-size: 12px;
  font-weight: 700;
}

.hejhome-input input {
  border: none;
  padding: 0;
}

.ant-input-focused, .ant-input:focus {
  box-shadow: none !important;
}

.ant-input-group-addon {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  width: 20px !important;
}

.ant-input-group-addon button {
  box-shadow: none;
  border: none;
  background-color: transparent;
  padding: 0;
  height: auto !important;
}

ul {
  padding: 0;
  list-style: none;
}

