.device-display.light {
  display: flex;
  justify-content: center;

  .display {
    background-color: #212226;
    width: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid rgba(138, 139, 141, .5);
  }

  .display ul {
    border-bottom: 1px solid rgba(138, 139, 141, .5);
    height: 64px;
    margin-bottom: 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    color: #8a8b8d;
    font-size: 16px;
  }

  .display ul li.active {
    color: #fff;
    font-weight: 700;
  }

  .display > div {
    display: flex;
    justify-content: center;
    padding: 50px;
  }

  .display > div svg path {
    transition: all .3s;
  }

  .display > div svg path.hide {
    opacity: 0;
  }
}

.preview {
  border: 1px solid #F7F4F1;
  border-radius: 16px;
  padding: 11px 16px;
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 12px;
  background: linear-gradient(0deg, rgba(255, 197, 43, 0.8), rgba(255, 197, 43, 0.8)), #FFFFFF;
  &.black {
    p {
      color : #000c17;
    }
  }
  p{
    color: #FFFFFF;
    margin-bottom: 0;
  }
}


.light-control {
  margin-top: 16px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 14px;
  background: #FFFFFF;
  border: 1px solid #CEC7BE;
  &.temperature {
    > div:nth-last-child(1) {
      grid-column: 1 / 2 span;
      background: linear-gradient(90deg, rgb(255,162,57), rgb(255,201,100), rgb(255,235,144), rgb(255,255,255), rgb(200,250,255));
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  &.saturation {
    > div:nth-last-child(1) {
      grid-column: 1 / 2 span;
      background: linear-gradient(270deg, #01EAF2 0.15%, #F7F4F1 92.72%);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  &.color {
    > div:nth-last-child(1) {
      grid-column: 1 / 2 span;
      background: linear-gradient(90deg, #FF0000 0%, #FF6E00 11.79%, #FFE600 22.5%, #F6E600 23.04%, #20E800 35.9%, #00FFF0 49.29%, #0038FF 63.22%, #9E00FF 75.01%, #FF00BC 88.41%, #FF0000 102.87%);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  &.brightness{
    > div:nth-last-child(1) {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 9.77%, rgba(255, 255, 255, 0.9) 9.78%, rgba(255, 255, 255, 0) 89.65%), #504D49;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .light-control-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-radius: 14px;
    background: #FFFBF1;
    padding: 16px;
    h2{
      margin-bottom: 0;
      font-weight: 700;
      font-size: 14px;
      color: #504D49;
    }
    .ant-input{
      border-radius: 7px;
      width: 83px;
      height: 28px;
      border: 0;
      padding: 4px 8px;
      background: #EFE8E1;
      color: #685B52;
    }
    .ant-input-number {
      border-radius: 7px;
      width: 83px;
      height: 28px;
      border: 0;
      padding: 4px 16px;
      background: #EFE8E1;

      .ant-input-number-handler-wrap {
        display: none;
      }

      input {
        height: auto;
        font-weight: 400;
        font-size: 14px;
        padding: 0;
        text-align: center;
        color: #685B52;
        border-radius: 7px;
      }

      span {
        color: #FFFFFF;
      }
    }
  }
  .light-control-footer{
    .ant-slider{
      margin: 10px 11px !important;
      position: relative;
      z-index: 1 !important;
      >.ant-slider-rail, .ant-slider-track {
        background-color: transparent;
      }

      >.ant-slider-handle {
        background: #FFFFFF;
        width: 20px;
        height: 20px;
        box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        border: 2px solid #FFFFFF;
        position: relative !important;
        z-index: auto !important;
        &::after {
          content: '';
          width: 1px;
          height: 32px;
          margin-left: 7px;
          top: -10px;
          display: block;
          opacity: 0.1;
          background: #10100E;
          position: absolute !important;
          z-index: -1 !important;
        }
      }
    }
  }
}
.alarm-setting-button {
  width: 138px;
  height: 44px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-top: 22px;
  font-weight: 700 !important;
  font-size: 14px !important;
  border: 1px solid #CEC7BE !important;
  border-radius: 16px !important;
  color: #685B52 !important;
  padding: 12px 16px !important;
  background-color: #FFFFFF;
  cursor: pointer;
}

.ant-slider-rail {
  background-color: transparent;
}

.ant-slider-track {
  background-color: transparent;
}
