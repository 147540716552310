.hejhome-join-container {
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

  .hejhome-join-process {
    width: 100%;
  }

  .hejhome-join-process h3 {
    color: #84827E;
    font-size: 24px;
    font-weight: 800;
  }

  .hejhome-join-process h1 {
    margin-bottom: 1rem;
    word-break: keep-all;
  }

  .hejhome-join-process p {
    color: #797672;
    font-size: 14px;
    font-weight: 500;
    word-break: keep-all;
    white-space: pre-line;
  }

  .hejhome-join-buttons {
    position: absolute;
    bottom: 10%;
    left: 128px;
  }

  .hejhome-join-buttons button {
    font-size: 12px;
    width: 104px;
    height: 34px;
    padding: 8px 16px;
    display: flex;
    border-radius: 8px !important;
  }

  .hejhome-join-buttons button svg {
    margin-right: 0.5rem;
  }

  .join-footer {
    display: flex;
    justify-content: flex-end;
  }

  .join-footer button {
      width: 92px;
      height: 48px;
      margin-top: 1rem;
  }
}

@media (max-width: 767.98px) {
  .hejhome-join-container {
    .hejhome-join-go-back-btn {
      width: 100%;
    }
    .hejhome-join-go-back-btn button {
      border: none;
      padding: 0;
    }

    .hejhome-join-process h3 {
      margin-top: 1.5rem;
      font-size: 16px;
    }

    .hejhome-join-process h1 {
      font-size: 24px;
    }
  }

  .join-footer button {
    width: 100% !important;
    //margin-top: 0 !important;
  }
}
