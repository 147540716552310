// antd Custom
// ----------------------------------------------------------------------------

main > div > div {
  padding: 0 0 1rem 0;
}

.ant-select {
  margin-right: 1rem;
}

.ant-picker {
  width: 50%;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-layout {
  background: #F7F4F1 !important;
}

.ant-layout-header {
  height: auto;
  display: flex;
  justify-content: space-between;
}

.ant-layout-footer {
  background: #F7F4F1 !important;
}

// Button
// ----------------------------------------------------------------------------
button {
  border-radius: 16px !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  background: #cec7be !important;
  color: #f7f4f1 !important;
  border: none;
}

// Checkbox
// ----------------------------------------------------------------------------
.ant-checkbox {
  top: 0 !important;
}

.ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
}

.ant-checkbox-wrapper {
  display: flex !important;
  align-items: center !important;
}

.ant-checkbox + span {
  color: #797672;
}

.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  filter: brightness(95%);
}

// PopOver
// ----------------------------------------------------------------------------
.ant-popover {
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.ant-popover-arrow {
  display: none !important;
}

.ant-popover-inner-content {
  padding: 0.5rem 0 !important;

  .pos-detail-popover {
    width: 256px;

    .popover-card-info {
      margin-left: 16px;

      p {
        color: #989691;
        font-size: 12px;
        font-weight: 600;
        height: 16px;
      }

      img {
        width: 224px;
        height: 128px;
      }
    }

    .popover-product-info {
      table {
        width: 224px;
        margin-left: 16px;
        margin-right: 16px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;

        thead {
          tr {
            border-top: solid 1px #504d49;
            border-bottom: solid 1px #504d49;
          }
        }

        tbody {
          tr {
            border-bottom: solid 1px #efe8e1;
          }
        }

        .info-list {
          color: #989691;
        }

        .info-title {
          color: #504d49;
        }

        .info-list,
        .info-title {
          height: 28px;

          .product-name {
            width: 50px;
            text-align: left;
          }

          .product-price,
          .product-amount,
          .product-total-price {
            width: 50px;
            text-align: right;
          }
        }

        .info-payment,
        .info-total-price {
          border-bottom: solid 1px #504d49;
          color: #504d49;
          font-size: 10px;
          font-weight: 500;

          .info-payment-type,
          .info-payment-title,
          .info-total-price-price,
          .info-total-price-title {
            color: #504d49;
            font-size: 10px;
            height: 28px;
            line-height: 12px;
            font-weight: 500;
          }

          .info-payment-title,
          .info-total-price-title {
            text-align: left;
          }

          .info-payment-type,
          .info-total-price-price {
            text-align: right;
          }
        }
      }
    }
  }
}

.ant-popover-inner {
  border: 1px solid #cec7be;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px !important;

  .space-edit-list {
    display: grid;
    grid-template-columns: repeat(1, 3fr);
    width: 200px;
    height: 148px;

    &.recipe {
      height: 100px;
    }

    &.edit {
      height: 192px;
    }

    > div {
      width: 100%;

      > span {
        display: flex !important;
        width: 168px;

        img {
          margin-right: 16px;
        }
      }
    }
  }

  .device-add-popover-container {
    width: 256px;
    height: 294px;
    padding: 1rem;

    display: flex;
    flex-direction: column;
  }

  .device-add-popover-container h3 {
    color: #504d49;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .device-add-popover-container img {
    width: 100px;
    margin-bottom: 1rem;
  }

  .device-add-popover-container span {
    word-break: keep-all;
    color: #504d49;
    font-size: 12px;
    margin-bottom: 1rem;
  }

  .space-edit-list > div {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
  }

  .space-edit-list > div > span {
    display: grid;
    grid-template-columns: 30px auto;
    color: #504d49;
    font-weight: 700;
    //cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .space-edit-list > .dashboard-edit {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 12px 1rem;
    color: #504d49;
    font-weight: 700;
    background-color: transparent;
    height: 44px;
    margin-bottom: -30px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  .space-edit-list > div.active,
  .dashboard-edit.active {
    background-color: #f7f4f1;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.0367 12.475L8.92235 15.5894L7.74384 14.4108L10.8582 11.2965C11.198 10.9567 11.4169 10.7369 11.5702 10.5563C11.7172 10.3831 11.7543 10.3016 11.7686 10.2576C11.823 10.0902 11.823 9.90996 11.7686 9.74259C11.7543 9.69861 11.7172 9.61705 11.5702 9.4439C11.4169 9.2633 11.198 9.04347 10.8582 8.70374L7.74384 5.58936L8.92235 4.41084L12.0594 7.54793C12.3701 7.85855 12.6381 8.12651 12.8407 8.36527C13.0547 8.61726 13.2444 8.89099 13.3537 9.22756C13.5169 9.72967 13.5169 10.2705 13.3537 10.7726C13.2444 11.1092 13.0547 11.3829 12.8407 11.6349C12.6381 11.8737 12.3701 12.1416 12.0594 12.4523C12.0519 12.4598 12.0443 12.4674 12.0367 12.475Z' fill='%23989691'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
}

.ant-popover-open {
}

.ant-popover-inner-content {
  height: 100%;
}

.ant-popover-title {
  div {
    border-bottom: 0;
  }
}

// Modal
// ----------------------------------------------------------------------------
.ant-modal-close {
  left: 0;
}

.ant-modal-content {
  width: 1000px;
  border: 1px solid #cec7be;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px !important;
  padding: 1rem;
}

.ant-modal-body {
  height: auto !important;
  width: 100% !important;
  padding-bottom: 0 !important;
}

.ant-modal-close-x {
  //margin-left: 1rem;
}

.ant-modal-footer {
  border-top: none !important;
}

// Form
// ----------------------------------------------------------------------------
.ant-form-item {
  margin-bottom: 0 !important;
}

// Drawer
// ----------------------------------------------------------------------------
.ant-drawer-content {
  border-radius: 24px !important;
}

// Input
// ----------------------------------------------------------------------------
//.ant-input-group .ant-input {
//  width: 100% !important;
//}
.ant-input-group-addon button {
  width: auto !important;
}

.ant-layout-sider-children {
  background: #ffffff !important;
  width: 240px;
  border-right: 1px solid #CEC7BE !important;

  img {
    width: 32px;
    height: 32px;
    margin: 32px;
  }

  > ul {
    li {
      color: #989691;
      height: auto !important;

      > .ant-menu-submenu-title {
        padding: 0 16px !important;
        border-radius: 24px;
        position: relative;
        z-index: 0;

        > .ant-menu-title-content {
          position: relative;
          z-index: 1;

          > a {
            display: block;
            position: relative;
            z-index: 100;
            width: 130px;
          }
        }
      }
    }

    > li {
      &:last-child {
        position: absolute;
        width: 100%;
        bottom: 0 !important;
      }
    }
  }

  ul {
    background: #ffffff !important;
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  padding-left: 20px;
}

//
//.ant-menu-submenu-title {
//  cursor: default !important;
//  pointer-events: none !important;
//}


.ant-menu-title-content {
  > a {
    font-weight: 700;
    color: #989691;
    font-size: 14px;
    cursor: pointer !important;
    z-index: 9999;
    pointer-events: auto;
  }
}

.ant-message {
  width: auto !important;
  height: 52px;
  position: absolute;
  color: #FFFFFF !important;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.12);
  left: 64px !important;
  bottom: 64px !important;
  top: unset !important;
  border-radius: 16px !important;
  background: #84827e !important;

  .ant-message-notice, .custom-message {
    margin-top: 0 !important;
  }

  .ant-message-notice-content {
    background: #84827e !important;
    color: #ffffff !important;
    border-radius: 16px !important;

    .ant-message-custom-content {
      background: #84827e !important;
      color: #ffffff !important;

      svg {
        display: none;
      }
    }
  }

  .alert-message {
    background: #84827e !important;
    border-radius: 16px !important;

    .ant-message-notice-content {
      background: #84827e !important;
      color: #ffffff !important;
      border-radius: 16px !important;

      .ant-message-custom-content {
        background: #84827e !important;
        color: #ffffff !important;

        svg {
          display: none;
        }
      }
    }
  }
}

// Date picker
// ----------------------------------------------------------------------------
.ant-picker-content {
  thead {
    tr {
      border-top: solid 1px #504d49;
      border-bottom: solid 1px #504d49;
      height: 52px;

      th {
        color: #504d49;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .ant-badge-status {
    width: 120px;
  }

  .ant-badge-status-text {
    color: #989691;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

.ant-tabs-ink-bar-animated {
  background: none !important;
}

.ant-tabs-nav:before {
  border: none !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: transparent !important;
  border-radius: 42px !important;
  border: 1px solid #CEC7BE !important;

  &:nth-child(1) {
    margin-left: 0 !important;
  }

  margin: 0 6px !important;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background: #504D49 !important;
}

// Time picker
// ----------------------------------------------------------------------------
.ant-picker-content {
  padding: 8px 4px;

  ul:nth-of-type(1) {
    order: 2;
    border-right: 1px solid #CEC7BE;
  }

  ul:nth-of-type(2) {
    order: 3;
    border-left: 0;
  }

  ul:nth-of-type(3) {
    order: 1;
    border-right: 1px solid #CEC7BE;
  }
}
