.password-form {

  .password-input-form {
    border: 1px solid #797672;
    border-radius: 16px;
    padding: 0.75rem 1rem;
    color: #504D49;
  }

  .password-input-form:nth-child(1) {
    margin-bottom: 1rem;
  }

  .password-input-form input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  .password-input-form.invalid:focus-within {
    border: 1px solid #BB3A3A;
  }

  .password-input-form:focus-within {
    border: 1px solid #00A872;
  }

  .password-message {
    color: #BB3A3A;
    font-size: 12px;
    margin-left: 1rem;
    height: 1.5rem;
    margin-top: 0.5rem;
  }
}
