
.code-modal {
  width: 480px !important;
  height: 280px !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  border: 1px solid #CEC7BE;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  h1{
    padding: 12px 16px;
    font-weight: 600;
    font-size: 32px;
    color: #504D49;
    word-break: keep-all;
    margin: 0;
  }
  p{
    padding: 12px 16px;
    font-weight: 400;
    font-size: 14px;
    color: #504D49;
    word-break: keep-all;
    margin: 0;
  }
  >.ant-modal-content{
    width: 480px;
    height: 296px;
    padding: 0 !important;
    >button {
      display: none;
    }
  }
}
