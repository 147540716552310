.setting-modal {
  width: 1280px !important;
  height: 800px;
  background: #ffffff !important;
  border-radius: 8px;

  >.ant-modal-content {
    width: 1280px !important;
    height: 800px;
    overflow-y: scroll;
    border-radius: 8px;
    padding: 24px !important;

    .ant-modal-body {
      padding: 0;
      background-color: #ffffff;

      .ant-layout {
        background-color: #ffffff !important;

        .site-layout {
          .ant-layout-content {
            margin: 16px 0 0 0;

            h1,
            p {
              margin: 0;
            }
          }
        }

        .ant-layout-sider {
          width: 152px !important;
          background-color: #ffffff;
          >.ant-layout-sider-children {
            background: #ffffff !important;
            width: 160px !important;
            ul {
              width: 152px;
              padding: 0 !important;
              border: 0;
              li {
                color: #989691;
                height: 44px !important;
                padding: 12px 16px !important;
                span {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 20px;

                  img {
                    width: 20px !important;
                    height: 20px !important;
                  }

                  p {
                    font-weight: 600;
                    font-size: 14px;
                    width: 84px;
                    margin-bottom: 0;
                  }
                }
              }

              li {
                &:last-child {
                  width: 153px;
                }
              }
              .ant-menu-item-selected {
                color: #FFFFFF;
                &:last-child {
                  background-color: #bb3a3a !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.setting-container {
  padding: 0;

  h1 {
    font-weight: 700;
    font-size: 32px;
  }

  p {
    font-weight: 700;
    font-size: 16px;
    color: #989691;
  }

  .setting-title {
    padding: 0;

    h1,
    p {
      margin-bottom: 24px !important;
    }
  }

  .account-manage-form {
    width: 378px;
    padding: 8px 0;
  }

  .account-input {
    border: 1px solid #797672;
    border-radius: 16px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 10px !important;
  }

  .account-input span {
    color: #504d49;
    font-size: 12px;
    font-weight: 700;
  }

  .account-input input {
    border: none;
    padding: 0;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: none;
  }

  .email-message {
    height: 12px;
    padding-left: 0.75rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: #bb3a3a;
    font-size: 12px;
  }

  .account-manage-button {
    display: flex;
    width: 158px;
    justify-content: space-between;
  }

  .account-manage-button a {
    text-decoration: underline;
    color: #797672;
  }

  .account-manage-button .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .account-manage-button button {
    width: 57px;
    height: 44px;
    font-size: 16px;
  }

  .save-message {
    color: #00a872;
    font-weight: 500;
    opacity: 0.5;
    font-size: 14px;
  }

  .setting-content {
    width: 100%;

    > p {
      margin-bottom: 24px !important;
    }

    .ant-divider {
      margin: 0;
    }

    li {
      p {
        margin-bottom: 0;
      }
    }

    .setting-item {
      width: 100%;
      height: 76px;
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }

      .setting-info {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 0 16px;

        h1 {
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 4px;
        }

        p {
          font-weight: 500;
          margin-bottom: 0;
          font-size: 12px;
          color: #989691;
        }
      }

      .peristalsis-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 16px;
      }

      button {
        height: 44px;
        border-radius: 16px;
        vertical-align: middle;

        span {
          text-align: center;
        }
      }
    }
  }

  .company-info {
    h1 {
      font-weight: 700;
      color: #989691;
      font-size: 16px;
      margin-bottom: 24px !important;
    }

    h2 {
      color: #989691;
      font-weight: 700;
      font-size: 14px;
    }

    .company-detail {
      color: #989691;
      margin-bottom: 8px;

      p {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 8px;
        }
      }
    }

    p {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

.setting-sider {
  margin-top: 24px;
  background-color: #ffffff;
}

.setting-layout-content {
  height: 732px;
  background-color: #ffffff;
}

.resign-modal {
  width: 480px !important;
  height: 480px;

  > .ant-modal-content {
    width: 480px !important;
    height: 480px;

    button {
      border-radius: 16px;
      width: 76px;
      height: 56px;
      //color: #504D49;
      .cancel {
        background-color: #f7f4f1;
      }
    }
  }

  .input-box {
    margin-bottom: 24px;
  }
}

.resign-password-input {
  width: 100%;
  height: 66px;
  border: 1px solid #797672 !important;
  border-radius: 12px !important;
  padding: 24px 16px 0 16px !important;
}

.resign-password-input-error {
  width: 100%;
  height: 66px;
  border: 1px solid red !important;
  border-radius: 12px !important;
  padding: 24px 16px 0 16px !important;
}

.input-item {
  > .ant-form-item-label {
    position: absolute;
    margin: 12px 16px;
    padding: 0;
    height: 18px !important;
    font-weight: 700;
    font-size: 12px;
    z-index: 999;

    > label {
      height: 18px !important;

      &:after {
        display: none;
      }
    }
  }
}

.input-item-error {
  > .ant-form-item-label {
    position: absolute;
    margin: 12px 16px;
    padding: 0;
    height: 18px !important;
    font-weight: 700;
    font-size: 12px;
    z-index: 999;

    > label {
      height: 18px !important;
      color: red;

      &:after {
        display: none;
      }
    }
  }
}

.toastcam-modal,
.airpos-modal {
  width: 480px !important;
  height: 608px;

  > .ant-modal-content {
    width: 480px !important;
    height: 608px;

    img {
      width: 95px;
      height: 24px;
      margin-bottom: 24px;
    }

    h1 {
      font-weight: 600;
      font-size: 32px;
    }

    p {
      margin-bottom: 0;
    }

    button {
      border-radius: 16px;
      width: 76px;
      height: 56px;
    }

    .login-box {
      margin: 32px 0 24px 0;
      height: 270px;

      .login-item {
        margin-bottom: 8px;
      }

      .login-error {
        font-weight: 500;
        font-size: 12px;
        margin: 8px 16px;
        color: #bb3a3a;
        //border: solid 1px blue;
        &.error {
          display: block;
        }
      }
    }
  }
}

.toastcam-modal{
  > .ant-modal-content{
    img {
      width: 240px;
    }
  }
}

.cam-list,
.pos-list {
  margin-top: 16px;
  width: 408px;
  max-height: 294px;
  overflow-y: scroll;
  padding-top: 8px;

  > .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .cam-item,
  .pos-item {
    margin: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    width: 400px;
    height: 64px;
    border: 1px solid #989691;
    border-radius: 16px;

    &.selected {
      background: #eef9f3;
      border: 2px solid #00a872;
      border-radius: 16px;
    }

    .ant-radio-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;

      .ant-radio {
        margin-bottom: 8px;
      }

      .cam-info,
      .pos-info {
        display: flex;
        gap: 16px;
        flex-direction: row;
        align-items: center;
      }
    }

    img {
      width: 32px !important;
      height: 32px !important;
      margin-bottom: 0 !important;
    }
  }
}

.cam-footer,
.pos-footer {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.cam-data,
.pos-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: 400px;
  height: 56px;
  background: #f7f4f1;
  border-radius: 16px;
  font-weight: 700;
  font-size: 16px;
  color: #797672;
  margin-bottom: 8px;

  img {
    width: 32px !important;
    height: 32px !important;
    margin-bottom: 0 !important;
  }
}

.cam-camera,
.pos-camera {
  height: 300px;

  .content {
    margin: 8px 0 !important;

    .cam-select,
    .pos-select {
      width: 100%;
      height: 66px;
      border: 1px solid #797672 !important;
      border-radius: 12px !important;
      padding: 24px 16px 0 16px !important;

      .ant-select-arrow {
        margin: 4px 0;
      }

      .ant-select-selector {
        border: 0;
        padding: 4px 0;
      }
    }
  }

  .warning {
    padding: 0 8px;
    color: #db6161;
    font-weight: 400;
    font-size: 12px;
  }
}

.cam-main,
.pos-main {
  height: 324px;

  .cam-detail,
  .pos-detail {
    margin-top: 16px;
    padding: 16px 24px;
    width: 400px;
    height: 118px;
    background: #f7f4f1;
    border-radius: 16px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #504d49;
    }
  }
}

.airpos-delete-modal, .toastcam-delete-modal {
  width: 480px !important;
  height: 444px;
  border: 1px solid #cec7be;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  > .ant-modal-content {
    width: 480px;
    height: 444px;

    img {
      width: 95px;
      height: 24px;
      margin-bottom: 24px;
    }

    h1 {
      font-weight: 600;
      font-size: 32px;
      word-break: keep-all;
    }

    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 14px;
      color: #504d49;
    }

    .ant-modal-footer {
      button {
        margin-top: 32px;
        border-radius: 16px;
        width: 84px;
        height: 56px;
      }
    }
  }
}
.toastcam-delete-modal {
  >.ant-modal-content {
    img{
      width: 240px;
    }
  }
}

.Change-PayMethod-Modal {
  width: 480px !important;
  height: 638px;
  border: 1px solid #cec7be;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  > .ant-modal-content {
    width: 480px;
    height: 638px;
  }

  button {
    margin-top: 24px;
    border-radius: 16px;
    width: 76px;
    height: 56px;
  }
}

.service-info-modal {
  width: 800px !important;
  height: 720px;

  > .ant-modal-content {
    width: 800px;
    max-height: 700px;
    overflow-y: scroll;
  }
}

.setting-logout-modal {
  width: 480px !important;
  height: 280px;
  background: #ffffff !important;
  border-radius: 8px;
  button {
    padding: 10px 16px;
    width: auto;
    height: 44px;
    span {
      line-height: 20px;
    }
  }

  .ant-modal-content {
    width: 480px !important;
    height: 280px;
    border-radius: 8px;
    padding: 24px !important;

    .ant-modal-body {
      padding: 0;
      margin: 16px;
      background-color: #ffffff;

      h1 {
        margin: 16px 0;
      }
    }

    .ant-modal-footer {
      margin-top: 60px;
    }
  }
}