
.device-setting {
  -webkit-user-select: none;
  -webkit-touch-callout: none;

  .loading {
    padding: 16px;
  }
}

.device-setting-body {
  display: flex;
  flex-direction: column;
  min-width: 360px;
  height: 100vh;
  padding: 24px;

  h2 {
    font-weight: 700;
    font-size: 14px;
    color: #504D49 !important;
    margin: 9px 0 9px 0 !important;
  }

  .device-setting-top {
    h1, p {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      word-wrap: normal;
    }

    margin-bottom: 24px;

    .device-setting-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: #504D49;
        margin-top: 9px;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        color: #504D49 !important;
      }

      .remain-battery {
        display: flex;
        vertical-align: center;
        align-items: center;
        gap: 12px;

        span {
          color: #84827E;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .power-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 32px;
        background: #4F433D;
        padding: 8px 4px;
        border: 1px solid #3B322E;
        border-radius: 32px;

        img {
          width: auto;
          height: 16px;
        }
      }
    }

    h1 {
      font-weight: 700;
      font-size: 24px;
      color: #685B52;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      color: #84827E;
      margin-bottom: 0;
    }
  }

  .device-display {
    padding: 16px;
    background: #F7F4F1;
    height: auto;
    border: 1px solid #CEC7BE;
    border-radius: 16px;

    h2 {
      margin: 0 0 9px 0 !important;
      color: #504D49;
      font-weight: 700;
      font-size: 14px;
    }

    &.alert {
      background: #932F2F;

      h1, h2 {
        color: #FFFFFF !important;
      }

      p {
        color: #F2BABA !important;
      }
    }

    .undetected-title {
      color: #8A8B8D;
    }

    .detected-title, .normal {
      color: #00A872 !important;
    }

    &.plug {
      .display {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 700;
          font-size: 14px;
          color: #989691;
          margin-bottom: 0;
        }

        .top {
          display: flex;
          flex-direction: column;
          justify-content: center;

          height: 110px;
          border-bottom: 1px solid rgba(138, 139, 141, .5);

          h1 {
            font-weight: 700;
            font-size: 40px;
            color: #00A872;
            margin-bottom: 0;
          }
        }

        .bottom {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-items: center;
          height: 100px;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0;
            height: 100%;
            width: 100%;

            span {
              font-weight: 700;
              font-size: 24px;
              color: #504D49;
            }
          }
        }
      }
    }

    &.powerstrip, &.switches {
      .display {
        &.switch-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1px;
          background-color: #CEC7BE;

          .switch-item {
            height: 100px;
            padding: 12px;
            width: auto;
            display: grid;
            background-color: #F7F4F1;
            grid-template-columns: repeat(1, 1fr);
            margin-bottom: 0;

            &.on {
              h1 {
                font-weight: 900 !important;
                font-size: 16px !important;
                color: #00A872 !important;
              }

              svg {
                path {
                  fill: #00A872;
                }
              }
            }

            h1 {
              grid-row: 1;
              grid-column: 1/2;
              margin-bottom: 0;
              font-weight: 500 !important;
              font-size: 16px !important;
              color: #84827E !important;
            }

            button {
              width: 32px;
              grid-row: 2;
              grid-column: 2/2;
              justify-self: end;
              align-self: center;
            }

            .control-header {
              display: flex;
              flex-direction: row;
              align-items: center;
              grid-column: 1/3;
              gap: 10px;
              width: auto;

              h1 {
                font-weight: 500;
                font-size: 16px;
                color: #84827E;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }

            &.odd {
              grid-row: 1;

              &.switch-1, &.switch-3, &.switch-5 {
                grid-column: 1/3 !important;
              }
            }
          }
        }
      }
    }

    &.relay {
      h1 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0;

        &.on {
          color: #00A872 !important;
        }
      }

      p {
        color: #989691;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    &.th {
      h2 {
        margin: 0 0 9px 0 !important;
        color: #504D49;
        font-weight: 700;
        font-size: 14px;
      }

      .display {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .temperature {
          display: flex;
          flex-direction: column;

          h1 {
            color: #DB6161;
          }
        }

        .humidity {
          display: flex;
          flex-direction: column;

          h1 {
            color: #6CA3D4;
          }
        }
      }
    }
  }

  .device-display .display {
    display: flex;
  }

  .device-control-wrap {
    margin-top: 16px;
    min-height: 300px;
  }

  &.alarm {
    .device-display {
      .display {
        display: flex;
        flex-direction: column;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #00A872;
        margin: 0;
      }

      p {
        color: #989691;
        font-weight: 700;
        font-size: 14px;
        margin: 0;
      }

      &.detected {
        background: #932F2F;

        h2, h1 {
          color: #FFFFFF !important;
        }

        p {
          color: #F2BABA;
        }
      }
    }

    .alarm-setting {
      margin-top: 16px;
      border: 1px solid #CEC7BE;
      border-radius: 16px;

      h2 {
        font-weight: 700;
        font-size: 14px;
        color: #4F433D;
        padding: 16px;
        margin: 0 !important;
        border-bottom: 1px solid #CEC7BE;
      }

      .alarm-setting-container {
        display: flex;
        flex-direction: column;
        margin: 8px 0;

        .alarm-setting-item {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;

          p {
            margin-bottom: 0;
            color: #84827E;
          }

          .ant-select {
            display: flex;
            align-items: center;
            width: 97px;
            height: 40px;
            border: 1px solid #BCB6AE;
            border-radius: 12px;
            color: #84827E;

            .ant-select-selector {
              border: 0;
              border-radius: 12px;

              input {
                color: #989691;
              }
            }
          }
        }
      }
    }
  }

  &.alarm-wrap {
    color: #8a8b8d;
    font-weight: 500;
    font-size: 16px;

    .display {
      display: flex;
      flex-direction: column;

      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #797672;
        margin-bottom: 0;
      }

      p {
        color: #989691;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .alarm-setting {
      margin-top: 16px;
      border: 1px solid #CEC7BE;
      border-radius: 16px;

      h2 {
        font-weight: 700;
        font-size: 14px;
        color: #4F433D;
        padding: 16px;
        border-bottom: 1px solid #CEC7BE;
      }

      .alarm-setting-container {
        display: flex;
        flex-direction: column;
        margin: 8px 0;

        .alarm-setting-item {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;

          p {
            margin-bottom: 0;
          }

          .ant-select {
            display: flex;
            align-items: center;
            width: 97px;
            height: 40px;
            border: 1px solid #BCB6AE;
            border-radius: 12px;

            .ant-select-selector {
              border: 0;
              border-radius: 12px;

              input {
                color: #989691;
              }
            }
          }
        }
      }
    }

    .alarm-setting-button {
      width: 138px;
      height: 44px !important;
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      margin-top: 22px;
      font-weight: 700 !important;
      font-size: 14px !important;
      border: 1px solid #CEC7BE !important;
      border-radius: 16px !important;
      color: #685B52 !important;
      padding: 12px 16px !important;
      background-color: #FFFFFF;
      cursor: pointer;
    }
  }

  &.doorLock {
    .device-display {
      .display {
        display: flex;
        flex-direction: column;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        color: #989691;
        margin: 0;
      }

      p {
        color: #989691;
        font-weight: 700;
        font-size: 14px;
        margin: 0;
      }

      &.detected {
        p {
          color: #932F2F;
        }
      }
    }

    .temporary-password-setting {
      width: 312px;
      height: 100px;
      padding: 16px;
      background: #F7F4F1;
      border: 1px solid #DBCDC2;
      border-radius: 16px;
      margin: 16px 0;

      h3 {
        font-weight: 700;
        font-size: 14px;
        color: #4F433D;
      }

      .password-button {
        height: 40px;
        padding: 4px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 10px;
          width: 138px;
          height: 32px;
          background: #FFFFFF;
          border: 1px solid #CEC7BE;
          border-radius: 12px !important;
          img {
            margin-right: 8px;
            width: auto;
            height: 16px;
          }
          p {
            font-weight: 500;
            font-size: 12px;
            color: #504D49;
          }
        }
      }
    }

    .doorLock-control-container {
      width: 312px;
      height: 52px;
      background: #F7F4F1;
      border: 1px solid #CEC7BE;
      border-radius: 16px;
      padding: 16px;
      &.remote {
        height: 149px;
      }
      .control-header{
        width: 280px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        h3 {
          font-weight: 700;
          font-size: 14px;
          color: #4F433D;
        }
      }
      .door-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        button {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 14px 24px;
          width: 132px;
          height: 81px;
          background: #FFFFFF;
          border: 1px solid #CEC7BE;
          border-radius: 16px;
          img{
            width: auto;
            height: 24px;
          }
          p {
            font-weight: 600;
            font-size: 14px;
            color: #504D49;
          }
        }
      }
    }
  }

  &.hub-wrap {
    h1 {
      margin: 0;
    }

    color: #8a8b8d;
    font-weight: 500;
    font-size: 16px;

    .device-connect-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background: #F7F4F1;
      height: 56px;
      border: 1px solid #CEC7BE;
      border-radius: 16px;
      margin-bottom: 16px;

      > p {
        color: #504D49;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin: 0;
      }

      .connect-device-count {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        width: 40px;
        height: 24px;
        background: #A29186;
        border-radius: 12px;

        p {
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 20px;
          margin:0;
        }
      }
    }

    .device-display {
      .ant-skeleton {
        padding: 16px !important;
      }
      height: 312px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 16px;

      .remote-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        height: 72px;
        border-bottom: 1px solid #EFE8E1;

        .remote-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          img {
            width: auto;
            height: 40px;
          }

          .remote-inform {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 176px !important;
            height: 46px;

            input {
              font-weight: 500;
              font-size: 14px;
              padding: 4px 8px;
              width: 176px;
              height: 28px;
              background: #EEF9F3;
              border: 1px solid #00A872;
              border-radius: 8px;
            }

            h3 {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 500;
              font-size: 14px;
              color: #504D49;
              margin: 0;
            }

            p {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin: 0;
              font-weight: 500;
              font-size: 12px;
              color: #989691;
            }
          }
        }

        .remote-inform {
          display: flex;
          flex-direction: column;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 24px;
          background-color: transparent;
          border: 0;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .add-device-button {
      width: 114px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      margin-top: 22px;
      font-weight: 700;
      font-size: 14px;
      border: 1px solid #CEC7BE;
      border-radius: 16px;
      color: #685B52;
      padding: 12px 16px;
      background-color: #FFFFFF;
      cursor: pointer;
      p{
        line-height: 20px;
        margin:0;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.air-conditioner-remote-wrap {
    h1 {
      margin-bottom: 6px;
    }

    .disabled {
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    }

    .device-display.air-conditioner {
      height: 256px;

      .display {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 700;
          font-size: 14px;
          color: #989691;
          margin-bottom: 0;
        }

        .top {
          display: flex;
          flex-direction: column;
          justify-content: center;

          height: 110px;
          border-bottom: 1px solid rgba(138, 139, 141, .5);

          h1 {
            font-weight: 700;
            font-size: 40px;
            color: #00A872;
            margin-bottom: 0;
          }
        }

        .bottom {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-items: center;
          height: 100px;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0;
            height: 100%;
            width: 100%;

            span {
              font-weight: 700;
              font-size: 24px;
              color: #504D49;
            }
          }
        }
      }
    }

    .temperature-control.air-conditioner {
      margin: 16px 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 74px;
      min-height: 74px !important;
      border: 1px solid #BCB6AE;
      border-radius: 16px;
      justify-items: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 103px;
        height: 72px;
        border: 0;
        gap: 0;
        border-right: 1px solid #BCB6AE;
        border-radius: 16px 0 0 16px !important;

        &:last-child {
          border-radius: 0 16px 16px 0 !important;
          border-left: 1px solid #BCB6AE;
          border-right: 0;
        }

        img {
          width: auto;
          height: 20px;
        }
      }

      .display.temperature {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 72px;
        padding: 16px;
        background: #F7F4F1;

        h3 {
          font-weight: 700;
          font-size: 12px;
          color: #817267;
          margin-bottom: 0;
        }

        p {
          font-weight: 700;
          font-size: 14px;
          color: #504D49;
        }
      }
    }

    .air-conditioner-control {
      height: 108px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;

      .control-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 108px;

        p {
          font-weight: 600;
          font-size: 14px;
          color: #989691;
        }

        button {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 93.33px;
          height: 84px;
          padding: 16px;
          background: #FFFFFF;
          border: 1px solid #CEC7BE;

          p {
            color: #504D49;
            font-weight: 600;
            font-size: 14px;
          }

          img {
            width: auto;
            height: 24px;
          }

          &.disabled {
            opacity: 0.5;
          }
        }


        &.more {
          button {
            justify-content: center !important;
            cursor: default;
            pointer-events: none;
          }
        }
      }
    }
  }

  &.tv-remote-wrap {
    h1 {
      margin: 0;
    }

    p {
      margin: 0;
    }

    height: 516px;

    .function-button-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 52px;
      border: 1px solid #BCB6AE;
      border-radius: 16px;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 103px;
        height: 50px;
        border: 0;
        gap: 0;
        border-right: 1px solid #BCB6AE;
        border-radius: 16px 0 0 16px !important;

        &:last-child {
          border-radius: 0 16px 16px 0 !important;
          border-right: 0;
        }

        img {
          width: auto;
          height: 20px;
        }
      }
    }

    .device-display.tv-remote {
      height: 160px;
      padding: 16px;
      background: #F7F4F1;
      border: 1px solid #CEC7BE;
      border-radius: 16px;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      margin-top: 16px;
      justify-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid #DBCDC2;
        border-radius: 8px !important;

        img {
          width: auto;
          height: 24px;
        }
      }

      .volume, .channel {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;

        p {
          color: #84827E;
          font-weight: 500;
          font-size: 16px;
        }

        button {
          width: 56px;
          height: 44px;
          padding: 0 16px;

          img {
            width: auto;
            height: 24px;
          }
        }
      }

      .navigate {
        width: 148px !important;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr !important;

        .left, .right {
          display: grid;
          grid-template-rows: 1fr 2fr 1fr;

          button {
            display: flex;
            align-items: center;
            justify-items: center;
            grid-row: 2/3;
            width: 36px;
            height: 60px;
          }
        }

        .center {
          display: grid;
          grid-template-rows: 1fr 1fr 1fr;
          justify-items: center;

          button {
            display: flex;
            align-items: center;
            justify-items: center;
            width: 60px;
            height: 40px;
          }
        }
      }
    }

    .device-control.tv-remote {
      margin: 16px 0;
      background-color: #ffffff !important;
      border: 0 !important;
      height: 272px;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 16px;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 93.33px;
          height: 56px;
          background: #FFFFFF;
          border: 1px solid #BCB6AE;
          border-radius: 16px;
          color: #504D49;
          font-weight: 700;
          font-size: 16px;
          cursor: pointer;

          img {
            width: auto;
            height: 24px;
          }
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 93.33px;
        height: 56px;
        background: #FFFFFF;
        border: 1px solid #BCB6AE;
        border-radius: 16px;

        img {
          width: auto;
          height: 24px;
        }
      }
    }
  }

  .device-control-wrap {
    .device-control-flex, .device-control {
      display: flex;
      justify-content: space-between;

      .switch-button {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: 148px;
        height: 84px;
        background: #FFFFFF;
        border: 1px solid #CEC7BE !important;
        border-radius: 16px !important;
        text-align: center;
        color: #8a8b8d;
        font-size: 16px;
        transition: all 0.2s linear;
        padding: 16px;

        span {
          height: 16px;
          font-weight: 600;
          font-size: 14px;
          color: #504D49;
        }

        &:hover {
          border: 1px solid #eaeaea;
          color: #eaeaea;
          cursor: pointer;
        }
      }
    }
  }

  .ref-setting {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px;
    height: 120px;
    border: 1px solid #CEC7BE;
    border-radius: 16px;

    h2 {
      color: #F7F4F1 !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      margin: 0 !important;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0;
    }

    &.temperature {
      background: #B46161;

      p {
        color: #F2BABA;
      }

      .ant-input-affix-wrapper {
        background: #932F2F;
      }

      input {
        background: #932F2F;
      }

      .ant-slider-track {
        background: #D0767B !important;
      }

    }

    &.humidity {
      background: #78B1E4;

      p {
        color: #C5E2F5;
      }

      .ant-input-affix-wrapper {
        background: #4979A5;
      }

      input {
        background: #4979A5;
      }

      .ant-slider-track {
        background: #92C3EB !important;
      }

    }

    h2 {
      color: #F7F4F1;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0;
    }

    .input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .input-item {
        display: flex;
        flex-direction: row;
        width: 105px;
        justify-content: space-between;
        align-items: center;

        .ant-input-number {
          border-radius: 7px;
          width: 70px;
          height: 30px;
          border: 0;

          .ant-input-number-handler-wrap {
            display: none;
          }

          input {
            padding: 0;
            text-align: center;
            color: #FFFFFF;
            border-radius: 7px;
          }

          span {
            color: #FFFFFF;
          }
        }
      }
    }

    .slider {
      .ant-slider {
        margin: 0 !important;
        padding: 0 !important;
        height: 16px;
      }

      .ant-slider-rail, .ant-slider-track {
        height: 16px;
        border-radius: 20px;
        background: #EAEAEA;
      }

      .ant-slider-handle {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        position: relative !important;
        opacity: 1;
        z-index: 1 !important;
        &.ant-slider-handle-2 {
          position:  relative !important;
          bottom: 16px;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}

.device-setting-footer {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  margin-top: 2rem;
}

.device-setting-footer .device-button {
  width: 100%;
}

.device-setting-footer .device-button button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  background-color: #212226;
  border: 1px solid rgba(138, 139, 141, .5);
  border-radius: 50px;
  height: 60px;
  font-size: 18px;

  transition: all .2s linear;
}

.device-setting-footer .device-button button:hover {
  border: 1px solid #eaeaea;
  cursor: pointer;
}

.device-setting-footer .device-button button svg {
  margin-right: 0.5rem;
}

.remote-device-register-modal {
  padding: 16px;
  width: 480px;
  height: 608px;

  .ant-modal-content {
    padding: 32px;
    width: 480px;
    height: 608px;

    .ant-modal-body {
      padding: 32px 0;

      h1 {
        font-weight: 700;
        font-size: 24px;
        margin: 0;
        color: #685B52;
      }

      p {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        color: #84827E;
      }
    }
  }
}

.remote-device-register-drawer {
  .ant-drawer-content-wrapper {
    height: 550px !important;

    .ant-drawer-content {
      height: 550px !important;
      border-radius: 24px 24px 0 0 !important;

      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          display: none;
        }

        h1 {
          font-weight: 700;
          font-size: 24px;
          margin: 0;
          color: #685B52;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          margin: 0;
          color: #84827E;
        }
      }

      .ant-drawer-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          padding: 12px 32px;
          width: 158.5px;
          height: 48px;
          border: 1px solid #BCB6AE;
          border-radius: 16px;
        }
      }
    }
  }
}

.category-container {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;

  .ant-radio-group {
    width: 100%;

    .category-list {
      width: 100%;
      display: flex;
      flex-direction: column;

      .category-content {
        width: 100%;
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        background: #F7F4F1;
        border: 1px solid #CEC7BE;
        border-radius: 16px;
        height: 72px;

        .category-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          img {
            width: auto;
            height: 40px;
          }

          p {
            margin: 0 0 0 24px;
            font-weight: 500;
            font-size: 14px;
            color: #504D49;
          }
        }
      }

      .brand-list {
        > p {
          font-weight: 500;
          font-size: 14px;
          color: #84827E;
          margin: 4px 0;
        }

        .brand-inform {
          width: 100%;
          padding: 16px 24px;
          height: 56px;
          margin-top: 4px;
          background: #FFFFFF;
          border: 1px solid #CEC7BE;
          border-radius: 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          p {
            font-weight: 500;
            font-size: 14px;
            color: #504D49;
            margin: 0;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .device-display {
  }

  .device-control {
  }

  .device-control button, .device-control .switch-button {
    width: 45% !important;
  }
}