.account-form {
  .account-input-form {
    border: 1px solid #797672;
    border-radius: 16px;
    padding: 0.75rem 1rem;
    color: #504D49;
  }

  .account-input-form label {
    color: #989691;
    font-weight: 700;
  }

  .account-input-form input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
}
