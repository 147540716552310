.view-list-container {
  height: 100vh;

  .view-list-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 90%;
    background-color: #000;
  }
  .view-list-9 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 90%;
    background-color: #000;
  }
  .view-list-16 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    height: 90%;
    background-color: #000;
  }

  .player-content {
    background-color: #000;
    display: flex;
    height: 100%;
    width: 100%;
    border: solid 0.5px gray;
    position: absolute;

    video {
      object-fit: fill;
      min-height: 100%;
      max-height: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .no-camera {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    color: #fff;
    margin: auto;
  }

  .player-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    //height: fit-content;
  }

  .player-text {
    display: flex;
    position: absolute;
    font-size: 18px;
    width: 100%;
    z-index: 1000;
    text-align: left;
    color: #ffffff;
    bottom: 0;

    .camera-name {
      margin-right: 12px;
    }

    .space-name {
      opacity: 0.5;
    }
  }

  .view-buttons {
    background-color: #1c1b18;
    //position: absolute;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 1rem;
    height: 10%;
    width: 100%;
  }

  .view-buttons .view-list-space-select {
    display: flex;
    align-items: center;
  }

  .view-buttons .view-list-space-select .ant-select-selector {
    display: flex;
    align-items: center;
    height: 100%;
    width: 129px;
    background-color: transparent;
    color: #fff;

    border: 1px solid #504d49;
    border-radius: 16px;
    padding: 0 1rem;
  }

  .view-buttons .view-list-space-select .ant-select-selection-item {
    font-weight: 700;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.52515 12.037L4.41077 8.9226L5.58928 7.74409L8.70366 10.8585C9.0434 11.1982 9.26322 11.4171 9.44382 11.5704C9.61698 11.7174 9.69853 11.7546 9.74251 11.7689C9.90988 11.8232 10.0902 11.8232 10.2575 11.7689C10.3015 11.7546 10.3831 11.7174 10.5562 11.5704C10.7368 11.4171 10.9566 11.1982 11.2964 10.8585L14.4108 7.74408L15.5893 8.9226L12.4522 12.0597C12.1416 12.3703 11.8736 12.6383 11.6349 12.841C11.3829 13.0549 11.1091 13.2446 10.7726 13.354C10.2705 13.5171 9.72959 13.5171 9.22748 13.354C8.89091 13.2446 8.61718 13.0549 8.36519 12.841C8.12644 12.6383 7.85848 12.3703 7.54786 12.0597C7.54032 12.0521 7.53274 12.0446 7.52515 12.037Z' fill='%23F7F4F1'/%3E%3C/svg%3E%0A");
    background-position: right center;
    background-repeat: no-repeat;
  }

  .view-buttons
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector:after {
    display: none;
  }

  button {
    border: none;
    background-color: transparent;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .divide-buttons {
    display: flex;
    margin-left: 1rem;
  }

  .divide-buttons svg {
    margin-right: 0.25rem;
  }

  .divide-buttons button {
    padding: 1rem;
    border: 1px solid #504d49;
  }

  .divide-buttons button.active {
    border: 1px solid #4bb98c !important;
    background-color: #03352c;
    color: #4bb98c;
  }

  .divide-buttons button.active svg path {
    fill: #4bb98c;
  }

  .divide-buttons button:nth-child(1) {
    border-radius: 16px 0 0 16px !important;
  }

  .divide-buttons button:nth-child(2) {
    border-radius: 0 !important;
    border-left: none;
  }

  .divide-buttons button:nth-child(3) {
    border-radius: 0 16px 16px 0 !important;
    border-left: none;
  }

  .extra-buttons {
    display: flex;
  }

  .extra-buttons button {
    border: 1px solid #504d49;
    border-radius: 16px !important;
    padding: 16px;
    margin: 0 0.5rem;

    &:nth-child(1) {
      margin-left: 1rem;
    }
  }

  .control-buttons {
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: 1rem;
  }

  .control-buttons span {
    padding: 1rem;
    border: 1px solid #504d49;
    height: 54px;
  }

  .control-buttons button {
    padding: 1rem;
  }

  .control-buttons button:nth-child(1) {
    border: 1px solid #504d49;
    border-right: none;
    border-radius: 16px 0 0 16px !important;
  }

  .control-buttons button:nth-last-child(1) {
    border: 1px solid #504d49;
    border-left: none;
    border-radius: 0 16px 16px 0 !important;
  }
}
