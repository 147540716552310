.join-terms-container {
  .join-terms {
    padding-left: 0;
    margin-bottom: 0;
  }

  .join-terms button {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .join-terms li {
    margin: 1rem auto;
    padding: 1rem 1.5rem 1rem 3rem;

    border: 1px solid #989691;
    box-sizing: border-box;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;

    display: flex;
    justify-content: space-between;

    background-color: #F7F4F1;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0892 1.58928L7.08916 11.5893C6.76372 11.9147 6.23608 11.9147 5.91064 11.5893L0.910645 6.58928L2.08916 5.41077L6.4999 9.82151L15.9106 0.410767L17.0892 1.58928Z' fill='%23BCB6AE'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left 1.5rem center;
  }

  .join-terms li:nth-last-child(1) {
    margin-bottom: 0;
  }

  .join-terms li.all-terms-agree button {
    text-decoration-line: none;
    font-weight: 500;
    width: 100%;
    text-align: left;

  }

  .join-terms li.active {
    background-color: #DDF2E7;
    border: 2px solid #00A872;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.0892 1.58928L7.08916 11.5893C6.76372 11.9147 6.23608 11.9147 5.91064 11.5893L0.910645 6.58928L2.08916 5.41077L6.4999 9.82151L15.9106 0.410767L17.0892 1.58928Z' fill='%23009368'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left 1.5rem center;
    color: #4BB98C;
  }

  .join-terms li.active button {
    color: #4BB98C;
  }

  .join-terms li strong {
    color: #DB6161;
  }

  .join-terms li.active strong {
    color: #4BB98C;
  }

  .join-terms li button {
    color: #989691;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .join-terms li span {
    cursor: pointer;
  }
}

@media (max-width: 767.98px) {
  .join-terms-container {
    .ant-drawer-header {
      display: none !important;
    }
  }
}

.term-drawer-button-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;

  .drawer-button {
    background: #fff;
    width: 100%;
    padding: 5%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .drawer-button button {
    width: 100%;
    background: #00A872;
    border-radius: 16px;
    height: 48px;
    font-size: 16px;
  }
}
