
.layout-setting-list {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 200px;

  button {
    height: 44px;
    padding: 12px 16px;
    display: flex;
    flex-direction: row;
    border: 0;
    font-weight: 600;
    font-size: 14px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
}

.ant-popover-hidden, .ant-zoom-big-appear-prepare {
  height: 0;

  .ant-popover-content {
    height: 0;

    .ant-popover-inner {
      height: 0;

      .ant-popover-inner-content {
        height: 0;

        .layout-setting-list {
          height: 0;

          button {
            height: 0;
          }
        }
      }
    }
  }
}

.ant-popover-inner {
  border: 1px solid #CEC7BE;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-layout-content {
  margin: 32px 32px 32px 62px;
}

.ant-tabs-tab {
  border-radius: 42px !important;
  color: #504D49 !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: red !important;
}

.ant-tabs-tab-active {
  background: #504D49 !important;
  color: #FFFFFF !important;

  > .ant-tabs-tab-btn {
    color: #FFFFFF !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFFFFF !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-remove {
  color: #FFFFFF !important;
  cursor: pointer;
}


.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab > .ant-tabs-tab-btn, .ant-tabs-tab-remove {
  color: #504D49;
  cursor: pointer;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  display: flex !important;
  flex-direction: row-reverse !important;

  button {
    margin: 0 !important;
    padding: 1px 6px 1px 0 !important;
  }
}

.ant-tabs-nav-wrap {
  border: 0;
}

.ant-tabs-tab-with-remove {
  flex-direction: row-reverse;

  .ant-tab-tab-btn {
    color: #FFFFFF !important;
  }
}

//.ant-menu {
//  .ant-menu-submenu {
//    &.ant-menu-submenu-selected {
//      background: #EEF9F3;
//      border-radius: 16px;
//
//      > .ant-menu-submenu-title {
//        background: #EEF9F3;
//      }
//    }
//  }
//
//}

.ant-menu {
  .ant-menu-item {
    &.color {
      background: #00A872;
    }
  }
}

.ant-menu .ant-menu-submenu, .ant-menu-item {
  &.ant-menu-submenu-inline {
    background: white;
  }
  &.ant-menu-submenu-selected {
    svg {
      path {
        fill: #00A872;
      }
    }

    a {
      color: #00A872 !important;
    }

    i {
      color: #00A872;
    }
  }

  &.color {
    svg {
      path {
        fill: white;
      }
    }

    a {
      color: white !important;
    }

    i {
      color: white;
    }
    > .ant-menu-submenu-title {
      background: #00A872;

      svg {
        path {
          fill: white;
        }
      }

      a {
        color: white;
      }

      i {
        color: white;
      }
    }
  }
}


.ant-menu .ant-menu-submenu-open {
  background: #EEF9F3;
  border-radius: 16px !important;


  &.ant-menu-submenu-selected {
    background: #EEF9F3;
    border-radius: 12px !important;

    > .ant-menu-submenu-title {
      background-color: #EEF9F3;
      border-radius: 14px;
    }

    svg {
      path {
        fill: #00A872 !important;
      }
    }

    a {
      color: #00A872 !important;
    }
  }


  //margin: 16px;
  font-weight: 700;
  font-size: 12px;

  > ul {
    background: #EEF9F3 !important;
    border-radius: 12px !important;
    padding: 8px;
  }
}

.ant-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #FFFFFF !important;
  background: #00A872 !important;

  svg {
    fill: #FFFFFF;
  }

  svg {
    path {
      fill: #FFFFFF !important;
    }
  }

  a {
    &.active {
      color: #FFFFFF !important;
    }
  }
}

.ant-menu.ant-menu-inline {
  padding: 16px;
}

.ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}

.ant-menu.ant-menu-inline.ant-menu-sub {
  li {
    padding: 4px 16px !important;
    height: 26px !important;
  }
}

.ant-menu.ant-menu-inline .ant-menu-item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  //padding: 4px 16px !important;
  height: 26px;
  text-align: left;
  font-weight: 700;
  border-radius: 10px;
  font-size: 12px;

  > svg {
    display: none;
  }

  &:hover {
    > svg {
      display: inline-block;
    }
  }

  img {
    width: 16px;
    height: 16px;
    margin: 0;
  }
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  font-weight: 700;
  font-size: 14px;
}

.ant-tabs-tab-remove {
  margin-left: 0 !important;
  cursor: pointer;
}

.layout > .ant-layout-sider-children > ul > li {
  &.ant-menu-item {
    padding-left: 16px !important;
    display: flex;
    justify-content: space-between;
    flex-direction: row !important;
    font-weight: 700;

    a {
      color: #989691;
    }

    font-size: 14px;

    svg {
      display: flex !important;
    }
  }

  &:first-child {
    cursor: default;
    pointer-events: none;
    margin: 16px 0 32px 4px !important;
    padding-left: 12px !important;

    span {
      img {
        width: 32px !important;
        height: 32px !important;
      }
    }
  }
}

.header-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 176px;
  grid-auto-flow: dense;
  direction: rtl;
}