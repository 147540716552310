.Change-Password-Modal {
  width: 480px !important;
  height: 480px;
  >.ant-modal-content {
    width: 480px !important;
    height: 480px;
  }
}
.Change-Password-Button-Ok,
.Change-Password-Button-Cancel {
  border-radius: 16px;
  width: 76px;
  height: 56px;
  font-size: 16px;
  border: solid 1px #bcb6ae;
  cursor: pointer;
}

.Change-Password-Button-Ok {
  background-color: #00a872;
  color: #f7f4f1;
  margin-left: 10px;
}

.Change-Password-Button-Cancel {
  background-color: #f7f4f1;
  color: #504d49;
}

.Change-Password-Desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.Change-Password-Input-Title {
  font-weight: 700;
  margin-top: 12px;
  margin-left: 16px;
  margin-bottom: 0;
}

.Change-Password-Input-Repeat-Desc-Normal,
.Change-Password-Input-New-Desc-Normal,
.Change-Password-Input-Original-Desc-Normal {
  border-radius: 15px;
  margin-bottom: 16px;
  border: solid 1px #797672;
  >span {
    padding: 4px 16px;
  }
}

.Change-Password-Input-Repeat-Desc-Warning,
.Change-Password-Input-New-Desc-Warning,
.Change-Password-Input-Original-Desc-Warning {
  border-radius: 15px;
  margin-bottom: 16px;
  border: solid 1px red;
}

.Change-PayMethod-Button-Ok,
.Change-PayMethod-Button-Cancel {
  border-radius: 16px;
  width: 142.5px;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  border: solid 1px #bcb6ae;
  cursor: pointer;
}

.Change-PayMethod-Button-Ok {
  background-color: #00a872;
  color: #f7f4f1;
  margin-left: 10px;
}

.Change-PayMethod-Button-Cancel {
  background-color: #f7f4f1;
  color: #504d49;
}
